import * as React from "react";
import Container from "../components/container";
import Layout from "../components/layout";
import { StaticImage } from "gatsby-plugin-image";
import {
  mainContent,
  description,
  row,
  //col6,
  col12,
  //marginTopSubstitute,
  introSection,
  //buttonGroup,
} from "./subpage.module.scss";
//import { Link } from "gatsby";

const DmeaBerlinPage = () => {
  return (
    <Layout pageTitle="DMEA Berlin 2023" neutralPage={true}>
      <main className={mainContent}>
        <section className={introSection}>
        <Container oneColumn={true}>
            <div className={row}>
              <div className={col12}>
                 <p className={description}>News - Februar 2023</p> 
                <h1>Die DMEA - Fachmesse Connecting Digital Health steht vor der Tür</h1>
               
                <p className="text-medium">
                Besuchen Sie uns vom <span className="text-blue"> 25. bis 27. April 2023 </span> am Schweizer Stand in Halle 3.2, Stand E-102  und entdecken 
                Sie unsere neuesten Entwicklungen, wie zum Beispiel das TIE 
                <a href="https://www.tie.ch/module/#patientenportal" target="_blank" rel="noreferrer" className="italic-blue"> Patientenportal</a>, das 
                <a href="https://www.tie.ch/module/#zuweiserportal" target="_blank" rel="noreferrer" className="italic-blue"> Zuweiserportal</a>, das Modul 
                  <a href="https://www.tie.ch/module/#md-akte" target="_blank" rel="noreferrer" className="italic-blue"> MD Akte</a> oder unsere universelle Lösung zur Digitalen Leistungsanforderung, das Modul 
                  <a href="https://www.tie.ch/module/#order-entry" target="_blank" rel="noreferrer" className="italic-blue"> Order-Entry</a>. 
                                </p>

                 <p>Dies alles und noch vieles mehr gerne auch in einer <span className="text-blue"> Live-Demo </span> an der DMEA in Berlin. Wir freuen uns darauf Sie begrüßen zu dürfen.</p>
                  </div>
                 <div className={col12}>
                <h3>Haben Sie Interesse an einem persönlichem Gespräch an der DMEA?</h3>
                <p>Treffen Sie unsere Experten am <span className="text-blue">Schweizer Stand in Halle 3.2, Stand E-102. </span> Für ein persönliches Gespräch buchen Sie am besten heute noch Ihren Termin über <a href="mailto:sales@tie.ch" className="text-blue">
                sales(at)tie.ch</a>. Wir freuen uns auf den Austausch mit Ihnen.</p>

                  <StaticImage
                    src="../assets/IMG_Ubersicht_klein.jpg"
                    alt="Screenshot Patientenportal KSBL"
                    placeholder="none"
                    layout="fullWidth"
                    formats={["auto", "webp", "avif"]}
                  />
                  
                  </div>

            </div>

            
          </Container>
        </section>
      </main>
    </Layout>
  );
};

export default DmeaBerlinPage;
